import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import { Grid } from '@mui/material';
import { channelDefaultValues, channelResolver, IChannelForm } from './models/form';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import providerChannel from '../../redux/actions/provider-channel';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import channel from '../../redux/actions/channel';

const ChannelFormDialog = ({ open, handleClose, data, provider }: any) => {
  const methods = useForm<IChannelForm>({ resolver: channelResolver });

  const loading = useAppSelector((state) => state.providerChannel.isLoadingSubmit);
  const params = useAppSelector((state) => state.providerChannel.params);
  const channelList = useAppSelector((state) => state.channel.dataAll);
  const dispatch = useAppDispatch();

  const onSubmit = async (value: any) => {
    try {
      if (data?.id) {
        await dispatch(providerChannel.updateData(data?.id, value));
      } else {
        await dispatch(providerChannel.createData(value));
      }
      alertSuccess(`Provider Channel berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(providerChannel.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  React.useEffect(() => {
    dispatch(channel.fetchAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data?.id && open === true) {
      methods.reset({ ...data, payment_provider_id: provider?.id, provider_name: provider?.name });
    } else {
      methods.reset({
        ...channelDefaultValues,
        payment_provider_id: provider?.id,
        provider_name: provider?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data?.id ? 'Edit' : 'Add'} Channel</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <InputField name="provider_name" label="Provider Name" disabled />
              <SelectField
                name="payment_channel_id"
                label="Payment Channel"
                options={channelList.map((data: any) => ({ value: data.id, label: data.name }))}
              />
              <InputField name="code" label="Channel Code" minLength={3} maxLength={6} />
              <InputField name="provider_channel_code" label="Provider Channel Code" />
              <InputField
                name="max_transaction"
                label="Maximum Transaction"
                type="number"
                min={0}
              />
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <InputField name="sla" label="SLA (Days)" type="number" min={0} />
                </Grid>
                <Grid item sm={6}>
                  <SelectField
                    name="capability"
                    label="Capability"
                    options={[
                      { label: 'Cash In', value: 1 },
                      { label: 'Cash Out', value: 2 },
                    ]}
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ChannelFormDialog;
